
          @import "@/assets/css/vars.scss";
        






















































































































































































































































.admin-panel {
  position: relative;
  height: 100%;
  padding: 0 0 50px 0;
  .wrapper {
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    .table-wrapper {
      position: relative;
    }
  }
  button {
    display: block;
    background-color: rgb(238, 131, 54);
    color: rgb(239, 236, 233);
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: Xiaomi-Regular;
    border: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    margin: 50px auto 0 auto;
  }
}
