
          @import "@/assets/css/vars.scss";
        





























































































.home-claim-12t-wrapper {
  width: 100%;
  .absolute-wrapper {
    height: 600vw;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
  }
  .xiaomi-12t-images {
    font-size: 0;
  }
}

@media only screen and (max-width: 1166px) {
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 725px) {
}
@media only screen and (max-width: 600px) {
}
