
          @import "@/assets/css/vars.scss";
        



































.imei-wrapper {
  background-color: #ededed;
  .content {
    width: 100%;
    padding: 95px 0 95px 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .left {
      img {
        width: 100%;
        max-width: none !important;
      }
    }
    .right {
    }
  }
}

@media only screen and (max-width: 725px) {
  .imei-wrapper {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .left {
        img {
          display: none;
        }
      }
      .right {
      }
    }
  }
}

@media only screen and (max-width: 1166px) {
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 600px) {
}
