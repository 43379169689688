
          @import "@/assets/css/vars.scss";
        


























.xiaomi-12t-image3-wrapper {
  background-color: #ededed;
  img {
    //   width: 100%;
    width: 100%;
    max-width: none !important;
  }
  .xiaomi-12t-image3-mobile {
    display: none;
  }
}

@media only screen and (max-width: 725px) {
  .xiaomi-12t-image3-wrapper {
    background-color: #ededed;
    img {
      display: none;
    }
    .xiaomi-12t-image3-mobile {
      display: block;
      img {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 1166px) {
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 600px) {
}
